
import { Component, Prop, Vue } from 'vue-property-decorator';
import { AuthorizedUser } from '@/types/users';
import UserInfoSummary from './UserInfoSummary.vue';
import { Action } from 'vuex-class';
import namespaces from '@/store/namespaces';
import { Logout } from '@/store/modules/auth-module/types';

@Component({
  components: { UserInfoSummary }
})
export default class AlreadyRegisteredUserProfile extends Vue {
  @Prop({ required: true }) user!: AuthorizedUser;

  @Action('logout', { namespace: namespaces.AuthModule })
  public logout!: Logout;

  public get userName() {
    const { NAME = '' } = this.user || {};
    const [ firstName ] = NAME.split(' ');
    return firstName;
  }

}
