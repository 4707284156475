
import { Component, Prop, Vue, Emit } from "vue-property-decorator";
import TextField from "@/ui-components/TextField/TextField.vue";
import { rules } from "@/validation-rules";
import { Action, Getter } from "vuex-class";
import namespaces from "@/store/namespaces";
import { UpdateDesignerEmail } from "@/store/modules/campaign-wizard/types";

@Component({
  components: {
    TextField
  }
})
export default class UploadLater extends Vue {
  public designerEmail = "";
  @Prop({ type: String }) selectedMediaOption!: string;
  @Prop({ type: Boolean }) disableRadioBtn!: boolean;

  @Emit("update:designerEmail")
  public updateValue(value: string) {
    if (rules.emailFormat("")(value)) {
      this.updateDesignerEmail(value);
    }
  }

  @Action("updateDesignerEmail", { namespace: namespaces.CampaignWizardModule })
  public updateDesignerEmail!: UpdateDesignerEmail;

  @Getter("designerEmail", { namespace: namespaces.CampaignWizardModule })
  public campaignWizardDesignerEmail!: string;

  public created() {
    this.designerEmail = this.campaignWizardDesignerEmail;
  }

  public get emailRules() {
    return [rules.emailFormat(this.$i18n.t("invalid_email") as string)];
  }
}
