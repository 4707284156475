
import { Component, Vue } from "vue-property-decorator";
import Media from "@/ui-components/Media/Media.vue";
import IButton from "@/ui-components/IButton/IButton.vue";
import IDialog from "@/ui-components/IDialog/IDialog.vue";
import { MediaFile } from '@/types/media';

@Component({
  components: { Media, IButton, IDialog },
})
export default class DesignSamplesDialog extends Vue {
  public isOpen = false;

  public activeIndex = 0;

  public samples = [
    "/assets/images/image 11.png",
    "/assets/images/Rectangle 278.png",
    "/assets/images/Rectangle 279.png",
    "/assets/images/Rectangle 280.png",
    "/assets/images/Rectangle 281.png",
  ];

  public get restSamples() {
    return this.samples.filter((_, index) => index !== this.activeIndex);
  }

  public mediaMetaData(sample: string): MediaFile {
    return {
      name: sample.split('/').slice(-1)[0],
      path: sample,
    };
  }

  public handleSlideChange(activeIndex: number) {
    this.activeIndex = activeIndex;
  }

  public open() {
    this.isOpen = true;
  }

  public close() {
    this.isOpen = false;
  }
}
