
import { Component, Prop, Vue } from "vue-property-decorator";
import IButton from '@/ui-components/IButton/IButton.vue';
import { publicRouteNames } from '@/route-names/public';
import { Campaign } from '@/types/campaigns';
import { CreditCardPaymentInfo } from '@/types/payment';

@Component({
  components: {
    IButton
  },
})
export default class PaySadad extends Vue {
  public sadadCode = 12345
  @Prop({ required: true }) public info!: CreditCardPaymentInfo;
  @Prop({ required: true }) public campaignId!: Campaign['ID'];

  public get campaignPaymentDateTime() {
    return new Date();
  }

  public submitCampaign() {
    // TODO : Add logic for submitting campaign
    this.$router.push({ ...publicRouteNames.CAMPAIGN_SUBMIT });
  }
}
