
import { Component, Vue, Watch } from 'vue-property-decorator';
import { TimeSlotSelection } from '@/types/time-slots';
import TimeSelection from './components/TimeSelection.vue';
import PriceSummary from '@/pages/shared/CampaignWizard/components/PriceSummary.vue';
import IButton from '@/ui-components/IButton/IButton.vue';
import DatePickerInput from '@/ui-components/DatePickerInput/DatePickerInput.vue';
import ComingSoon from '@/ui-components/ComingSoon/ComingSoon.vue';
import WizardHeader from '../WizardHeader.vue';
import TextField from '@/ui-components/TextField/TextField.vue';
import { LocationData, LocationPrice } from '@/types/locations';
import { Action, Getter } from 'vuex-class';
import namespaces from '@/store/namespaces';
import { UpdateTime } from '@/store/modules/campaign-wizard/types';
import { rules } from '@/validation-rules';
import moment from 'moment';
import { CampaignFrequency } from '@/types/campaigns';
import { defaultCampaignFrequency } from '@/statics/campaign-wizard';
import { priceByFrequency } from '@/utils/numbers';

@Component({
  components: {
    PriceSummary,
    DatePickerInput,
    TextField,
    IButton,
    WizardHeader,
    ComingSoon,
    TimeSelection,
  },
})
export default class CampaignTime extends Vue {
  public valid = false;
  locationPrices = {} as Record<LocationData['ID'], LocationPrice>
  public TODAY = moment().add('days', 1).format('YYYY-MM-DD');

  public requiredRule = rules.required('This field is required');

  public durationRules = [
    this.requiredRule,
    rules.onlyPositiveNumber('Only positive number allowed'),
    rules.maxLength(2, this.$t('max_length', {
      field: this.$t('duration_small').toString(),
      maxLength: 99
    }).toString()),
  ];
  public startDateRules = [
    this.requiredRule,
    rules.minDate(this.TODAY, 'Please choose date after today'),
  ];

  public calendarIcon = '/assets/icons/calendar.svg';

  @Getter('selectedLocations', { namespace: namespaces.CampaignWizardModule })
  public campaignWizardSelectedLocations!: LocationData[];

  @Getter('time', { namespace: namespaces.CampaignWizardModule })
  public campaignWizardSelectedTimeSlots!: TimeSlotSelection;

  @Action('updateTime', { namespace: namespaces.CampaignWizardModule })
  public updateTime!: UpdateTime;

  public get selectedFrequency() {
    return this.campaignWizardSelectedTimeSlots.frequency as CampaignFrequency;
  }

  public set selectedFrequency(frequency: CampaignFrequency) {
    this.updateTime({
      ...this.campaignWizardSelectedTimeSlots,
      frequency
    });
  }

  public get startDate() {
    return this.campaignWizardSelectedTimeSlots.startDate as string;
  }

  public set startDate(startDate: string) {
    this.updateTime({
      ...this.campaignWizardSelectedTimeSlots,
      startDate
    });
  }

  public get duration() {
    return this.campaignWizardSelectedTimeSlots.duration as number;
  }

  public set duration(duration: number) {
    this.updateTime({
      ...this.campaignWizardSelectedTimeSlots,
      duration
    });
  }


  public timeOptions = 'fixed';

  public previousStep() {
    this.$emit('previous-step');
  }

  public nextStep() {
    this.$emit('next-step');
  }
  updatePrice(){
      const {
        frequency = defaultCampaignFrequency
      } = this.campaignWizardSelectedTimeSlots;
      this.locationPrices = this.campaignWizardSelectedLocations.reduce((selectedLocations,selectedLocation:LocationData)=>{
        return { ...selectedLocations,
          [selectedLocation.clusterId]: {
            BASE_PRICE: priceByFrequency(selectedLocation.BASE_PRICE, frequency)
          }
        }
      },{})
    }
  @Watch('campaignWizardSelectedTimeSlots.frequency')
  updatePriceOnFrequencyChanged(){
    this.updatePrice()
  }
  created(){

    this.updatePrice()
  }
}
