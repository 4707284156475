import { ScreenSpecs } from '@/types/screens';

export const verticalScreenSpecs: ScreenSpecs = {
  size: '14 x 48',
  orientation: 'portrait',
  supportedExtensions: 'JPG, MP4',
  recommendedResolution: 72,
  supportedVideoExtensions: 'MP4, MOV, AVI',
  frameRate: 30,
};
