
import { Component, Vue, Prop } from 'vue-property-decorator';
import { AuthorizedUser } from '@/types/users';
import UserInfoSummary from './UserInfoSummary.vue';
import { Action } from 'vuex-class';
import namespaces from '@/store/namespaces';
import { Logout } from '@/store/modules/auth-module/types';

@Component({
  components: { UserInfoSummary },
})
export default class NewRegisteredUserProfile extends Vue {
  @Prop({ required: true }) user!: AuthorizedUser;

  @Action('logout', { namespace: namespaces.AuthModule })
  public logout!: Logout;
}
