
import { Vue, Component, Watch } from 'vue-property-decorator';
import { termsAndConditionsLink, privacyPolicyLink, returnPolicyLink } from '@/statics/main';

@Component({})
export default class PaymentTermsAndPrivacy extends Vue {
  public terms = false;
  public privacy = false;

  @Watch('terms')
  updateTerms(newTerms: boolean) {
    this.$emit('update:terms', newTerms)
  }

  public openTermsAndConditions(event: Event) {
    event.preventDefault();
    window.open(termsAndConditionsLink, '_blank')!.focus()
  }

  public openPrivacyPolicy(event: Event) {
    event.preventDefault();
    window.open(privacyPolicyLink, '_blank')!.focus()
  }
}
