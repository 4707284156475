
import { Component, Vue, Emit } from 'vue-property-decorator';
// @ts-ignore
import { RecycleScroller } from 'vue-virtual-scroller/dist/vue-virtual-scroller.esm.js'
import Map from '@/ui-components/Map/Map.vue';
import LocationCard from '@/ui-components/LocationCard/LocationCard.vue';
import IButton from '@/ui-components/IButton/IButton.vue';
import { LocationData } from '@/types/locations';
import SelectedLocationsSummary
  from '@/pages/shared/CampaignWizard/components/Locations/components/SelectedLocationsSummary.vue';
import namespaces from '@/store/namespaces';
import { Action, Getter } from 'vuex-class';
import {
  SelectLocation,
  DeselectLocation,
} from '@/store/modules/campaign-wizard/types';
import { LoadLocations } from '@/store/modules/common-module/types';
import { publicRouteNames } from '@/route-names/public';
import LocationFilters from '@/pages/shared/CampaignWizard/components/Locations/components/LocationFilters.vue';
import Pagination from '@/ui-components/Pagination/Pagination.vue';
import Loading from '@/ui-components/LocationCard/components/Loading.vue';
import LocationDetails from '@/ui-components/LocationDetails/LocationDetails.vue';
import { cloneDeep } from 'lodash';
import { sortLocationsByPriorityOfAppearance } from '@/utils/locations';


@Component({
  components: {
    LocationDetails,
    LocationFilters,
    SelectedLocationsSummary,
    LocationCard,
    Map,
    IButton,
    Pagination,
    Loading,
    RecycleScroller,
  },
})
export default class Locations extends Vue {
  public activeLocationId = '';
  public detailedLocationId = '';
  public loading = false;
  public showMapView = false;
  public chunkIndex = 1;
  showGoToTopBtn = false;

  @Getter("locations", { namespace: namespaces.CommonModule })
  public locations!: LocationData[];

  @Getter("selectedLocations", { namespace: namespaces.CampaignWizardModule })
  public selectedLocations!: LocationData[];

  @Action("loadLocations", { namespace: namespaces.CommonModule })
  public loadLocations!: LoadLocations;

  @Action("selectLocation", { namespace: namespaces.CampaignWizardModule })
  public selectLocation!: SelectLocation;

  @Action("deselectLocation", { namespace: namespaces.CampaignWizardModule })
  public deselectLocation!: DeselectLocation;

  public filteredLocations: LocationData[] = [];
  public allLocations: Record<string, LocationData> = {};

  get isMobileViewPort() {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  get filteredLocationsSortedByPriority() {
    return sortLocationsByPriorityOfAppearance(this.filteredLocations);
  }

  set filteredLocationsSortedByPriority(locations) {
    this.filteredLocations = locations;
  }
  public async mounted() {
    this.loading = true;
    this.filteredLocations = await this.loadLocations() || [];
    this.loading = false;
    this.allLocations = cloneDeep(this.filteredLocations).reduce((locations, location) => {
      return {
        ...locations,
        [location.ID]: location
      }
    }, {} as Record<string, LocationData>);
    if (this.filteredLocations) {
      const scrollElement = document.getElementById('locations-section');
      scrollElement?.addEventListener(
        'scroll', this.scrollHandler)
    }
  }

  public isLocationSelected({ ID }: LocationData) {
    return this.selectedLocations.some(({ ID: locationId }) => locationId === ID);
  }

  public close() {
    this.$router.push({
      ...publicRouteNames.LOGIN,
    })
  }
  public clearFilters() {
    const locationFilters = this.$refs.locationFilters as LocationFilters
    locationFilters.clearFilters()
  }

  scrollHandler() {
    const scrollElement = document.getElementById('locations-section');
    this.showGoToTopBtn = scrollElement!.scrollTop > 100;
  }

  scrollToUp() {
    const scrollTarget = document.getElementById('locations-section');
    scrollTarget?.scrollTo({
      behavior: 'smooth',
      top: 0
    })
  }

  @Emit()
  public nextStep() {
    return true;
  }
}
