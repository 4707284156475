
import { Component, Prop, Vue } from 'vue-property-decorator';
import { creditCardBrands } from '@/statics/payment';
import { CreditCardPaymentInfo } from '@/types/payment';
import { Campaign } from '@/types/campaigns';
import { onlyNumbersRegex } from '@/validation-rules';
import MultiSelect from '@/ui-components/MultiSelect/MultiSelect.vue';
import TextField from '@/ui-components/TextField/TextField.vue';
import IButton from '@/ui-components/IButton/IButton.vue';
import SkeletonLoader from '@/ui-components/SkeletonLoader/SkeletonLoader.vue';
import Checkbox from '@/ui-components/Checkbox/Checkbox.vue';
import PaymentDisabledForm from '@/pages/shared/CampaignWizard/components/Payment/components/PaymentDisabledForm.vue';
import PaymentTermsAndPrivacy from '@/pages/shared/CampaignWizard/components/Payment/components/PaymentTermsAndPrivacy.vue';
import { observe } from '@/utils/dom';
import { returnPolicyLink } from '@/statics/main';

@Component({
  components: {
    MultiSelect,
    TextField,
    IButton,
    SkeletonLoader,
    Checkbox,
    PaymentDisabledForm,
    PaymentTermsAndPrivacy,
  },
})
export default class PayCreditCard extends Vue {
  @Prop({ required: true }) public info!: CreditCardPaymentInfo;
  @Prop({ required: true }) public campaignId!: Campaign['ID'];
  @Prop({ required: true }) public checkoutId!: string;
  @Prop({ required: true }) public returnUrl!: string;

  callbackUrl = ''
  valid = false;
  loading = false;
  terms = false;
  returnPolicyLink = returnPolicyLink;

  onlyNumbersRegex = onlyNumbersRegex;

  brands = creditCardBrands;

  get brandIcon() {
    const [brand] = this.brands.filter(
      (brand) => brand.value === this.info.brand
    );
    return brand && brand.icon;
  }

  mounted() {
    if (this.checkoutId) {
      this.loadCheckoutData(this.checkoutId, this.returnUrl);
    }
    this.paymentFormCustomize();
  }


  public loadScript(url: string, onload: () => any) {
    (window as any).wpwlOptions = {
      locale: this.$root.$i18n.locale,
      style: 'plain',
      iframeStyles: {
        'card-number-focus': {
          'border-color': 'red',
        },
        'card-number-placeholder': {
          color: 'rgba(93,90,112, .5)',
          'font-size': '16px',
        },
        'cvv-placeholder': {
          color: 'rgba(93,90,112, .5)',
          'font-size': '16px',
        },
      },
    };

    const paymentScriptId = 'paymentScript';
    const paymentScript = document.getElementById(paymentScriptId);
    if (paymentScript) {
      paymentScript.parentNode!.removeChild(paymentScript);
    }

    const script = document.createElement('script');
    script.id = paymentScriptId;
    script.type = 'text/javascript';

    script.onload = onload;

    script.src = url;
    document.getElementsByTagName('head')[0].appendChild(script);
  }

  async loadCheckoutData(checkoutId: string, returnUrl: string): Promise<void> {
    this.loading = true;

    try {
      const paymentGatewayDomain = process.env.VUE_APP_HYPER_PAY_DOMAIN;
      const paymentGatewayUrl = `${paymentGatewayDomain}/v1/paymentWidgets.js?checkoutId=${checkoutId}`;

      this.loadScript(paymentGatewayUrl, () => {
        this.callbackUrl = returnUrl;
      });
    } catch (err) {
      throw new Error(
        this.$t('something_went_wrong').toString() + '::' + err.message
      );
    } finally {
      this.loading = false;
    }
  }

  public paymentFormCustomize() {
    let finder = setInterval(() => {
      const iframe = document.querySelector('iframe.wpwl-control');
      const submitButton = document.querySelector('.wpwl-button');

      if (iframe && submitButton) {
        clearInterval(finder);

        observe(
          'iframe.wpwl-control',
          {
            attributesList: ['style'],
            attributeOldValue: true,
          },
          ({ attributeName, oldValue, target }: any) => {
            if (attributeName === 'style' && !oldValue) {
              target.setAttribute('style', 'border-color: #92298D');
            }
          }
        );

        submitButton.innerHTML = this.$t('pay_submit_campaign') as string;
        submitButton.setAttribute('data-pay-and-submit-campaign','');
      }
    }, 250);
  }
}
