
import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "MultiSelect",
})
export default class MultiSelect extends Vue {
  @Prop({ type: String }) label!: string;
  @Prop({ type: String }) placeholder!: string;
  @Prop({ type: String }) appendIcon!: string;
  @Prop({ type: String }) appendLabel!: string;
  @Prop({ type: String }) value!: string;
  @Prop({ type: String }) itemText!: string;
  @Prop({ type: String }) itemValue!: string;
  @Prop({ type: Boolean }) required!: boolean;
  @Prop({ type: Boolean }) disabled!: boolean;
  @Prop({ type: Boolean, default: true }) fullWidth!: boolean;
  @Prop({ type: Object, default: () => ({ offsetY: true }) }) menuProps!: any;
  @Prop({ type: Array }) rules!: any[];
  @Prop({ type: Array }) items!: any[];

  @Emit("input")
  public update(value: string) {
    return value;
  }

  public mounted() {
    this.setMenuMinimumWidth();
  }

  public setMenuMinimumWidth() {
    try {
      const vSelect = this.$refs.select as any;
      const select = vSelect.$refs.$el as HTMLElement;
      const minWidth = select.clientWidth;
      this.defaultMenuProps.minWidth = minWidth;
    } catch {}
  }

  public defaultMenuProps = {
    minWidth: 0,
  };
}
