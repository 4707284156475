
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { PaymentOptionData } from '@/types/payment';
import ComingSoon from '@/ui-components/ComingSoon/ComingSoon.vue';

@Component({
  components: {
    ComingSoon,
  },
})
export default class PaymentOption extends Vue {
  @Prop({ type: Object, default: () => ({}) }) public option!: PaymentOptionData;
  @Prop({ type: Boolean, default: false }) public selected!: boolean;
  @Prop({ type: Boolean, default: false }) public disabled!: boolean;
  @Prop({ type: Boolean, default: false }) public comingSoon!: boolean;

  public get multipleIcons() {
    return Array.isArray(this.option.icon);
  }

  @Emit('select')
  public handleSelection() {
    return this.option.value;
  }
}
