
import { Component, Vue, Watch } from 'vue-property-decorator';
import IButton from '@/ui-components/IButton/IButton.vue';
import Stepper from '@/ui-components/Stepper/Stepper.vue';
import StepperContent from '@/ui-components/Stepper/StepperContent.vue';
import StepperItems from '@/ui-components/Stepper/StepperItems.vue';
import StepperStep from '@/ui-components/Stepper/StepperStep.vue';
import StepperHeader from '@/ui-components/Stepper/StepperHeader.vue';
import StepperHeaderResponsive from '@/ui-components/Stepper/StepperHeaderResponsive.vue';
import { Action, Getter } from 'vuex-class';
import namespaces from '@/store/namespaces';
import Whatsapp from '@/pages/shared/CampaignWizard/components/Whatsapp.vue';
import Locations from './components/Locations/Locations.vue';
import CampaignTime from './components/Time/Time.vue';
import AdContent from './components/AdContent/AdContent.vue';
import Account from './components/Account/Account.vue';
import Payment from './components/Payment/Payment.vue';
import PriceSummary from '@/pages/shared/CampaignWizard/components/PriceSummary.vue';
import MediaUploadGuidelines from '@/shared-components/MediaUploadGuidelines.vue';
import { LocationData, LocationPrice } from '@/types/locations';
import { defaultCampaignFrequency, steps } from '@/statics/campaign-wizard';
import {
  CheckoutCampaign,
  DeselectLocation,
  InitNewCampaign,
  UpdateStep,
} from '@/store/modules/campaign-wizard/types';
import { TimeSlotSelection } from '@/types/time-slots';
import { ShowSnackbar } from '@/types/snackbar';
import RightPaymentSection from '@/pages/shared/CampaignWizard/components/Payment/components/RightPaymentSection.vue';
import { priceByFrequency } from '@/utils/numbers';

@Component({
  components: {
    RightPaymentSection,
    Stepper,
    StepperContent,
    StepperItems,
    StepperStep,
    StepperHeader,
    Account,
    Whatsapp,
    Locations,
    CampaignTime,
    IButton,
    AdContent,
    Payment,
    StepperHeaderResponsive,
    PriceSummary,
    MediaUploadGuidelines,
  },
})
export default class CampaignWizard extends Vue {
  @Getter('step', { namespace: namespaces.CampaignWizardModule })
  public currentStep!: number;
  public loading = true;

  @Getter('isAuthenticated', { namespace: namespaces.AuthModule })
  public isAuthenticated!: boolean;
  @Getter("selectedLocations", { namespace: namespaces.CampaignWizardModule })
  public selectedLocationsVuex!: LocationData[];

  @Getter('selectedLocations', { namespace: namespaces.CampaignWizardModule })
  public campaignWizardSelectedLocations!: LocationData[];

  @Getter('time', { namespace: namespaces.CampaignWizardModule })
  public campaignWizardSelectedTimeSlots!: TimeSlotSelection;

  @Action('updateStep', { namespace: namespaces.CampaignWizardModule })
  public updateStep!: UpdateStep;

  @Action('initNewCampaign', { namespace: namespaces.CampaignWizardModule })
  public initNewCampaign!: InitNewCampaign;
  @Action("deselectLocation", { namespace: namespaces.CampaignWizardModule })
  public deselectLocation!: DeselectLocation;
  @Action('checkoutCampaign', { namespace: namespaces.CampaignWizardModule })
  public checkoutCampaign!: CheckoutCampaign;
  @Action('showSnackbar', { namespace: namespaces.UiModule })
  public showSnackbar!: ShowSnackbar;
  public selectedLocations: LocationData[] = [];
  public isPublic = true;
  public locationPrices = {} as Record<LocationData['ID'],LocationPrice>;
  public get steps() {
    return steps.filter(
      ({ onlyPublic }) =>
        this.isPublic || (!onlyPublic || (this.isPublic && onlyPublic))
    );
  }

  public get paymentStepNumber() {
    return this.isPublic ? 5 : 4;
  }
  updatePrice(){
    const {
      frequency = defaultCampaignFrequency
    } = this.campaignWizardSelectedTimeSlots;
    this.locationPrices = this.campaignWizardSelectedLocations.reduce((selectedLocations,selectedLocation:LocationData)=>{
      return { ...selectedLocations,
        [selectedLocation.clusterId]: {
          BASE_PRICE: priceByFrequency(selectedLocation.BASE_PRICE, frequency)
        }
      }
    },{})
  }
  public initWizard() {
    this.initNewCampaign();
    this.isPublic = !this.isAuthenticated;
    this.updatePrice()
  }

  public created() {
    this.initWizard();
    this.initNewCampaign();
  }

  public previousStep() {
    this.updateStep(this.currentStep - 1);
  }

  public nextStep() {
    this.updateStep(this.currentStep + 1);
  }

  public goToStep(step: number) {
    this.updateStep(step);
  }
  @Watch('campaignWizardSelectedTimeSlots', { deep: true })
  updatePriceOnFrequencyChanged(){
    this.updatePrice()
  }
  @Watch('campaignWizardSelectedLocations', { deep: true })
  updatePriceOnSelectedLocationChanged(){
    this.updatePrice()
  }

  @Watch('isAuthenticated', { deep: true })
  async authenticationChange(val: boolean, prevVal: boolean) {
    const hasUserLoggedOut = !val && prevVal;

    if (hasUserLoggedOut) {
      location.reload();
    }
  }
}
