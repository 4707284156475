
import { Component, Vue, Watch } from 'vue-property-decorator';
import IButton from '@/ui-components/IButton/IButton.vue';
import Divider from '@/ui-components/Divider/Divider.vue';
import { PaymentData, PaymentOption } from '@/types/payment';
import { TimeSlotSelection } from '@/types/time-slots';
import { LocationData } from '@/types/locations';
import PriceSummary from '../PriceSummary.vue';
import CampaignSummary from './components/CampaignSummary.vue';
import PayCreditCard from './components/PayCreditCard.vue';
import PaySadad from './components/PaySadad.vue';
import PayLater from './components/PayLater.vue';
import PaymentOptions from './components/PaymentOptions.vue';
import namespaces from '@/store/namespaces';
import { Action, Getter } from 'vuex-class';
import { CheckoutCampaign, SaveCampaign, UpdatePayment } from '@/store/modules/campaign-wizard/types';
import { MediaData } from '@/types/media';
import { ShowSnackbar } from '@/types/snackbar';
import SkeletonLoader from '@/ui-components/SkeletonLoader/SkeletonLoader.vue';
import { Campaign } from '@/types/campaigns';
import PromoCodeInput from '@/shared-components/PromoCodeInput.vue';
import { Discount } from '@/types/promotions';
import RightPaymentSection from '@/pages/shared/CampaignWizard/components/Payment/components/RightPaymentSection.vue';
@Component({
  components: {
    SkeletonLoader,
    IButton,
    PriceSummary,
    CampaignSummary,
    PayCreditCard,
    PaySadad,
    PayLater,
    PaymentOptions,
    Divider,
    PromoCodeInput,
    RightPaymentSection,
  },
})
export default class Payment extends Vue {
  @Getter('selectedLocations', { namespace: namespaces.CampaignWizardModule })
  public selectedLocations!: LocationData[];

  @Getter('savedCampaign', { namespace: namespaces.CampaignWizardModule })
  public campaign!: Campaign;

  @Getter('media', { namespace: namespaces.CampaignWizardModule })
  public media!: MediaData;

  @Getter('name', { namespace: namespaces.CampaignWizardModule })
  public name!: string;

  @Getter('time', { namespace: namespaces.CampaignWizardModule })
  public timeSlotSelection!: TimeSlotSelection;

  @Getter('payment', { namespace: namespaces.CampaignWizardModule })
  public campaignWizardPaymentData!: PaymentData;

  @Getter('discounts', { namespace: namespaces.CampaignWizardModule })
  public discounts!: Discount[];

  @Action('updatePayment', { namespace: namespaces.CampaignWizardModule })
  public updatePayment!: UpdatePayment;

  @Action('showSnackbar', { namespace: namespaces.UiModule })
  public showSnackbar!: ShowSnackbar;

  @Action('checkoutCampaign', { namespace: namespaces.CampaignWizardModule })
  public checkoutCampaign!: CheckoutCampaign;

  @Action('saveCampaign', { namespace: namespaces.CampaignWizardModule })
  public saveCampaign!: SaveCampaign;

  public loading = true;
  public promoCodeLoading = false;
  public createdCampaignId = '';

  public get paymentData(): PaymentData {
    return this.campaignWizardPaymentData;
  }

  public get totalDiscount(): number {
    return this.paymentData.totalDiscount || 0;
  }

  public get returnUrl() {
    return this.paymentData.metaData?.returnUrl;
  }

  public get campaignId() {
    return this.createdCampaignId || this.campaign?.ID;
  }

  public set campaignId(id: string) {
    this.createdCampaignId = id;
  }
  public get checkoutId() {
    return this.paymentData.metaData?.checkoutId;
  }

  public set paymentData(payment: PaymentData) {
    this.updatePayment(payment);
  }

  public get isPayCreditCard() {
    return this.paymentData.option === 'CREDIT_CARD';
  }

  public get isPaySadad() {
    return this.paymentData.option === 'SADAD';
  }

  public get isPayLater() {
    return this.paymentData.option === 'PAY_LATER';
  }

  public async loadCheckoutData() {
    try {
      this.loading = true;
      this.promoCodeLoading = true;
      await this.checkoutCampaign();
    } catch (err) {
      console.log('err',err)
      this.paymentErrorSnackbar();
    } finally {
      this.loading = false;
      this.promoCodeLoading = false;
    }
  }

  public handlePaymentOptionChange(option: PaymentOption) {
    this.paymentData = {
      ...this.paymentData,
      option,
    };
  }

  public back() {
    this.$emit('previous-step');
  }

  @Watch('paymentData', { deep: true })
  public paymentOptionChanged(oldData: PaymentData, newData: PaymentData) {
    const { option: oldOption } = oldData;
    const { option: newOption } = newData;

    if (oldOption !== newOption) {
      this.paymentData.info = {};
    }
  }

  private paymentErrorSnackbar() {
    const color = 'danger';
    const text = this.$t('something_went_wrong') as string;
    this.showSnackbar({ text, color });
  }

  async checkAndSaveCampaign() {
    try {
      const { ID: id } = await this.saveCampaign();
      this.campaignId = id;
      setTimeout(() => {
        this.showSnackbar({
          color: 'info',
          text: this.$t('campaign_saved').toString(),
        });
      }, 5000)

    } catch (err) {
      console.log('err save campaign',err)
      this.showSnackbar({
        color: 'danger',
        text: this.$t('error_saving_campaign').toString() + '::' + (err as Error).message,
      });
    }
  }

  public async created() {
    await this.checkAndSaveCampaign();
    await this.loadCheckoutData();
  }
}
