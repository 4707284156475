
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { paymentOptions } from '@/statics/payment';
import { PaymentOption as IPaymentOption } from '@/types/payment';
import PaymentOption from './PaymentOption.vue';
import { Getter } from 'vuex-class';
import namespaces from '@/store/namespaces';
import { LocationData } from '@/types/locations';
import { isAllSelectedLocationsPriceControlByShasha } from '@/utils/campaigns';

@Component({
  components: {
    PaymentOption,
  },
})
export default class PaymentOptions extends Vue {
  @Prop({ type: String, default: null }) public selected!: IPaymentOption;
  public paymentOptions = paymentOptions;

  @Getter('selectedLocations', { namespace: namespaces.CampaignWizardModule })
  public selectedLocations!: LocationData[];

  get isAllSelectedLocationsPriceControlByShasha() {
    return isAllSelectedLocationsPriceControlByShasha(this.selectedLocations)
  }

  public isSelected(option: IPaymentOption) {
    return this.selected === option;
  }

  @Emit('change')
  public handleSelection(option: IPaymentOption) {
    return option;
  }

  created() {
      this.paymentOptions.map((paymentOption) => {
        if (paymentOption.value === 'CREDIT_CARD') {
          paymentOption.disabled = !this.isAllSelectedLocationsPriceControlByShasha;
          if(!this.isAllSelectedLocationsPriceControlByShasha){
            this.handleSelection(null)
          }
        }
        return paymentOption
      })

  }
}
