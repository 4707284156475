
import { Component, Vue, Prop } from 'vue-property-decorator';
import TextField from '@/ui-components/TextField/TextField.vue';
import MultiSelect from '@/ui-components/MultiSelect/MultiSelect.vue';
import IButton from '@/ui-components/IButton/IButton.vue';
import { rules } from '@/validation-rules';
import { LoginUserData } from '@/types/users';
import { Action } from 'vuex-class';
import namespaces from '@/store/namespaces';
import SnackBar from '@/ui-components/SnackBar/SnackBar.vue';
import { publicRouteNames } from '@/route-names/public';
import { errors } from '@/statics/errors';
import { ShowSnackbar } from '@/types/snackbar';

@Component({
  name: 'LoginForm',
  components: {
    IButton,
    MultiSelect,
    TextField,
    SnackBar,
  },
})
export default class LoginForm extends Vue {
  @Prop({ required: true }) onSuccess!: () => {};
  @Prop({ type: Boolean, default: true }) withForgotPassword!: boolean;

  public valid = false;
  public showPassword = false;
  public loading = false;
  public emailError = false;
  public passwordError = false;

  public registerRoute = publicRouteNames.REGISTER;
  public forgetPasswordRoute = publicRouteNames.FORGOT_PASSWORD;

  public eyeIcon = '/assets/icons/eye-o.svg';
  public eyeSlashIcon = '/assets/icons/eye-slash-o.svg';

  public user: LoginUserData = {
    email: '',
    password: '',
  };

  public get emailRules() {
    return [
      rules.required(this.$i18n.t('please_enter_your_email') as string),
      rules.testEmailFormat(this.$i18n.t('invalid_email') as string),
      () => !this.emailError || this.$i18n.t('email_not_found'),
    ];
  }

  public get passwordRules() {
    return [
      rules.required(this.$i18n.t('please_enter_your_password') as string),
      () => !this.passwordError || this.$i18n.t('wrong_password'),
    ];
  }

  public handleLoginSuccess() {
    this.loading = false;
    this.onSuccess();
  }

  public handleLoginFailure(error: any) {
    try {
      const { code } = error;
      const { EMAIL_NOT_FOUND_ERROR, WRONG_PASSWORD } = errors;

      if (code === EMAIL_NOT_FOUND_ERROR) {
        this.emailError = true;
      }

      if (code === WRONG_PASSWORD) {
        this.passwordError = true;
      }
    } catch (error) {
      const text = this.$t('login_error') as string;
      const color = 'danger';
      this.showSnackbar({ text, color });
    } finally {
      const form = this.$refs.form as any;
      this.loading = false;
      form.validate();
    }
  }

  @Action('login', { namespace: namespaces.AuthModule })
  public loginAction: any;

  @Action('showSnackbar', { namespace: namespaces.UiModule })
  public showSnackbar!: ShowSnackbar;

  public async login() {
    try {
      this.loading = true;
      await this.loginAction(this.user);
      this.handleLoginSuccess();
    } catch (error) {
      this.handleLoginFailure(error);
    }
  }
}
