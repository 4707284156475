
import { Component, Vue, Watch } from 'vue-property-decorator';
import debounce from 'lodash/debounce';
import IButton from '@/ui-components/IButton/IButton.vue';
import MediaUploadGuidelines from '@/shared-components/MediaUploadGuidelines.vue';
import TextField from '@/ui-components/TextField/TextField.vue';
import { MediaData, MediaFile, MediaOption } from '@/types/media';
import { manyWhitespacesRegex, rules } from '@/validation-rules';
import { verticalScreenSpecs } from '@/statics/screens';
import ScreenSpecs from '@/shared-components/ScreenSpecs.vue';
import CampaignNameDialog from './components/CampaignNameDialog.vue';
import NeedHelp from './components/NeedHelp.vue';
import WizardHeader from '../WizardHeader.vue';
import UploadLater from './components/UploadLater.vue';
import UploadNow from './components/UploadNow.vue';
import { Action, Getter, Mutation } from 'vuex-class';
import namespaces from '@/store/namespaces';
import { UpdateMedia, UpdateName } from '@/store/modules/campaign-wizard/types';
import { LocationData } from '@/types/locations';
import { isUniqueCampaignName } from '@/actions/campaigns/actions';
import { Campaign } from '@/types/campaigns';
import { ShowSnackbar } from '@/types/snackbar';

@Component({
  components: {
    IButton,
    TextField,
    ScreenSpecs,
    NeedHelp,
    UploadLater,
    UploadNow,
    WizardHeader,
    CampaignNameDialog,
    MediaUploadGuidelines
  },
})
export default class AdContent extends Vue {
  public valid = false;
  public isUploading = false;
  public isValidEmail = false;
  public designerEmail = '';
  public debouncedIsUniqueCampaignName: ((campaignName: string, isShowSnackBar?: boolean) => Promise<void> | undefined) | null = null;
  public isNewCampaignName = false;
  public campaignNameHint = this.$t('to_help_you_to_identify_campaign_easily').toString();

  @Getter('selectedLocations', { namespace: namespaces.CampaignWizardModule })
  public selectedLocations!: LocationData[];

  @Getter('media', { namespace: namespaces.CampaignWizardModule })
  public campaignWizardMedia!: MediaData;

  @Getter('isAuthenticated', { namespace: namespaces.AuthModule })
  public isAuthenticated!: boolean;

  @Getter('name', { namespace: namespaces.CampaignWizardModule })
  public campaignWizardCampaignName!: string;

  @Getter('designerEmail', { namespace: namespaces.CampaignWizardModule })
  public campaignWizardCampaignDesignerEmail!: string;

  @Getter('savedCampaign', { namespace: namespaces.CampaignWizardModule })
  public savedCampaign!: Campaign;

  @Action('updateMedia', { namespace: namespaces.CampaignWizardModule })
  public updateMedia!: UpdateMedia;

  @Action('updateName', { namespace: namespaces.CampaignWizardModule })
  public updateCampaignName!: UpdateName;

  @Action('showSnackbar', { namespace: namespaces.UiModule })
  public showSnackbar!: ShowSnackbar;

  @Mutation('syncRequiredMediaResolutions', { namespace: namespaces.CampaignWizardModule })
  public syncRequiredMediaResolutions!: any;

  public get selectedMediaOption() {
    return this.campaignWizardMedia.option!;
  }

  public set selectedMediaOption(option: MediaOption) {
    this.updateMedia({
      ...this.campaignWizardMedia,
      option,
    });
  }

  public get selectedMediaFiles(): MediaFile[] {
    return this.campaignWizardMedia.files!;
  }

  public set selectedMediaFiles(files: MediaFile[]) {
    this.updateMedia({
      ...this.campaignWizardMedia,
      files,
    });
  }

  public get campaignName() {
    return this.campaignWizardCampaignName;
  }

  public get nextStepText() {
    return this.isAuthenticated
      ? this.$t('review_payment')
      : this.$t('enter_account_details');
  }

  public set campaignName(name: string) {
    this.updateCampaignName(name);
  }

  public get campaignNameRules() {
    return [
      rules.required(this.$i18n.t('please_enter_your_campaign_name') as string),
      this.isNewCampaignName || this.$i18n.t('campaign_name_already_exists'),
      rules.maxLength(30,this.$t('max_length', {
        field: this.$t('campaign_name_small').toString(),
        maxLength: 30
      }).toString()),
    ];
  }

  public isValidateCampaignName(campaignName: string) {
    return [
      rules.required(this.$i18n.t('please_enter_your_campaign_name') as string),
    ].map((validateFunction) => {
      return validateFunction(campaignName)
    })
      .filter((results) => results !== true).length === 0;
  }

  public get isMediaOptionSelected(): boolean {
    if (this.selectedMediaOption === 'UPLOAD_NOW') {
      return Boolean(this.selectedMediaFiles && this.selectedMediaFiles.length)
    }

    return Boolean(this.selectedMediaOption);
  }

  public get screenSpecs() {
    return verticalScreenSpecs;
  }

  public get disableNextStep() {
    return !this.valid || this.isUploading ||
      !this.isMediaOptionSelected || !this.isCompleteMediaUploads ||
      (this.selectedMediaOption === 'UPLOAD_LATER' &&
        this.isValidEmail
      );
  }

  @Watch('designerEmail')
  designerEmailChanged(value: string) {
    this.isValidEmail = !rules.emailFormat('')(value)
  }

  public handleOptionSelection(value: string) {
    if (value === 'UPLOAD_NOW') {
      this.syncRequiredMediaResolutions();
    } else {
      this.selectedMediaFiles = [];
    }
  }

  public async mounted() {
    this.checkCampaignName();
    this.debouncedIsUniqueCampaignName = debounce(async (name, isShowSnackBar) => {
      this.isNewCampaignName = await isUniqueCampaignName(name, this.savedCampaign?.ID || '');
      if (isShowSnackBar && !this.isNewCampaignName) {
        this.showSnackbar({ text: this.$t('campaign_name_already_exists').toString(), color: 'danger' })
      }
      this.campaignNameHint = this.$t('to_help_you_to_identify_campaign_easily').toString();
    }, 200);
    this.debouncedIsUniqueCampaignName(this.campaignName, true);
  }

  public nextStep() {
    this.$emit('next-step');
  }

  public back() {
    this.$emit('previous-step');
  }

  public openCampaignNameDialog() {
    const dialog = this.$refs.campaignNameDialogRef as CampaignNameDialog;
    dialog.open();
  }

  private checkCampaignName() {
    if (!this.campaignName) {
      this.openCampaignNameDialog();
    }
  }

  get isCompleteMediaUploads(): boolean {
    if (this.selectedMediaOption === 'UPLOAD_NOW') {
      return Boolean(this.selectedMediaFiles.length)
        && this.selectedMediaFiles.every(media => Boolean(media.path));
    }

    return true;
  }

  @Watch('campaignWizardCampaignName')
  async campaignNameChanged(newCampaignName: string) {
    this.campaignName = newCampaignName.replace(manyWhitespacesRegex, ' ').trimStart()
    this.isNewCampaignName = true;
    if (this.isValidateCampaignName(newCampaignName.trim())) {
      this.campaignNameHint = this.$t('checking').toString();
      await this.debouncedIsUniqueCampaignName!(newCampaignName.trim());
    }

  }
}
