
import { Component, Prop, Vue } from 'vue-property-decorator';
import DesignSamplesDialog from './DesignSamplesDialog.vue';
import GuideLinesDialog from './GuideLinesDialog.vue';

@Component({
  components: {
    DesignSamplesDialog,
  },
})
export default class NeedHelp extends Vue {
  @Prop({ type: String }) selectedMediaOption!: string;

  public openGuideLinesModal() {
    const dialog = this.$refs.guideLinesDialogRef as GuideLinesDialog;
    dialog.open();
  }

  public openDesignSamplesModal() {
    const dialog = this.$refs.designSamplesDialogRef as DesignSamplesDialog;
    dialog.open();
  }

  public downloadGuideLines() {}
}
